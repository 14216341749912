//BUTTONS
.btn {
  font-size: 12px;
  border: 2px solid transparent;
  height: 44px;
  padding: 3px 30px 0 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
}

.btn-lg {
  font-size: 18px;
  height: 54px;
  padding: 3px 60px 0 60px;
  @media (min-width: $desktop) {
    font-size: 14px;
    height: 40px;
  }
}

.btn-block {
  padding-left: 15px;
  padding-right: 15px;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  background-color: transparent !important;
  border-color: rgba(255, 255, 255, 0.3) !important;
  color: #fff !important;
  &:hover {
    background-color: #fff !important ;
    color: #212529 !important ;
  }
}
.btn-outline-light {
  &,
  &:hover,
  &:focus {
    border-color: rgba(#fff, 0.3);
    color: #fff;
    background-color: transparent;
    box-shadow: none;
  }
  &:active {
    box-shadow: none !important;
    background-color: transparent !important;
    border-color: rgba(255, 255, 255, 0.3) !important;
  }
  @media (min-width: $desktop) {
    &:hover {
      background-color: #fff;
      color: #000 !important;
    }
  }
}

.btn-light {
  background-color: #fff;
  color: $black;
}

.btn-link {
  color: #fff;
  text-decoration: none;
  padding: 0;
  height: auto;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
  @media (min-width: $desktop) {
    &:hover {
      opacity: 0.7;
    }
  }
}
.btn-icon {
  img {
    margin-left: 10px;
    margin-top: -4px;
  }
}
