//MOBILE MENU
#mobile-menu {
  background-color: #000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 98;
  padding-top: 80px;
  overflow-y: auto;
  display: none;
  opacity: 0;
  @media (min-width: $desktop) {
    display: none !important;
  }
  .development-mode & {
    opacity: 1;
    display: block;
    @media (min-width: $desktop) {
      display: block !important;
    }
    ul {
      li {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 20px;
      opacity: 0;
      transform: translateX(-20px);
      a {
        display: block;
        color: #fff;
        font-size: 24px;
        padding-left: 20px;
        &:active {
          opacity: 0.5;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.hamburger-menu {
  position: absolute !important;
  top: 10px;
  transition: all;
  transform: rotate(270deg) !important;
  left: 25px;
  width: 60px;
  z-index: 9;

  @media (min-width: $desktop) {
    display: none;
  }
}

.nasted-menu {
  @media (max-width: $desktop) {
    flex-direction: column;
    display: none;
    li {
      padding-left: 30px;
      margin-bottom: 0 !important;
      a {
        font-size: 20px !important;
        font-style: italic;
      }
    }
  }
}
